body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #110E1B;
  color: #f7f7f7;
  font-size: 16px;
  overflow-x: hidden;
  position: relative;
}

.contrast1 {
  color: #f47607;
}

.contrast2 {
  color: #76c5f0;
}

::-moz-selection {
  background: #76c5f0;
  color: #f47607;
}

::selection {
  background: #76c5f0;
  color: #f47607;
}


html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1e272e;
}

::-webkit-scrollbar-thumb {
  background: #28343d;
  border-radius: 25px
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}