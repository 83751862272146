.job {
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: space-evenly;
  border-radius: 25px;
  overflow: hidden;
  margin: 20px;
}

@media screen and (max-width: 610px) {
  .job {
    width: 100%;
  }
}

@media screen and (max-width: 510px) {
  .job {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .job {
    height: 250px;
  }
}

@media screen and (max-width: 450px) {
  .job {
    height: 150px;
    width: 300px;
  }
}

@media screen and (max-width: 350px) {
  .job {
    height: 150px;
  }
}