.contrast1 {
  color: #f47607;
}

.contrast2 {
  color: #76c5f0;
}

.menup {
  display: none;
}

.nav {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100px;
  background-color: #110E1B;
  font-size: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .logo {
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  
    img {
      font-size: 2rem;
      height: 75px;
    }
  }
  
  .elements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
  }
  
  .links {
    width: 45%;
    font-size: 1.3rem;
  
    ul {
      display: flex;
      list-style: none;
      justify-content: space-evenly;
      align-items: center;
    }
  }
  
  .links ul li p {
    cursor: pointer;
  }
  
  li a p {
    height: 100%;
    width: 100%;
  }
  
  li a p::after {
    display: block;
    content: '';
    border-bottom: solid 2px #f47607;
    transition: 250ms ease-in-out;
    width: 0px;
  
  }
  
  li a p:hover::after {
    width: 100%;
  }
  
}


.main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc(100vh - 150px);
  .bg1 {
    height: calc(100vh - 200px);
    width: 100%;
    position: absolute;
    opacity: 0.75;
  }
}

.landing {
  height: calc(100vh - 150px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;

  .content {
    width: 100%;
    font-size: 1.5rem;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  
    .text {
      // font-size: 3rem;
      font-weight: 400;
      text-align: center;
    }
  }
  
}


.custom-shape-divider-top-1698073894 {
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 1;
}

.custom-shape-divider-top-1698073894 svg {
  position: relative;
  display: block;
  width: calc(120% + 1.3px);
  height: 120px;
}

.custom-shape-divider-top-1698073894 .shape-fill {
  fill: #110E1B;
}


.custom-shape-divider-bottom-1697467613 {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 1;
}

.custom-shape-divider-bottom-1697467613 svg {
  position: relative;
  display: block;
  width: calc(120% + 1.3px);
  height: 120px;
}

.custom-shape-divider-bottom-1697467613 .shape-fill {
  fill: #110E1B;
}

.scrollsection {
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding-bottom: 75px;

  svg {
    height: 25px;
  }

  .wrapper {
    animation: fade-slide-up 1s infinite alternate-reverse;
  }

  .scroll-top {
    position: relative;
    top: 20px;
  }
  
  .scroll-bot {
    position: relative;
    top: 5px;
  }
  
}

a {
  color: #f7f7f7;
  text-decoration: none;
}

.stopka {
  width: 100%;
  text-align: center;

  a {
    text-decoration: underline;
  }
}


@keyframes fade-slide-up {
  0% {
    opacity: 0.5;
    scale: 0.9;
  }
  100% {
    opacity: 0.9;
    scale: 1;
  }
}

.container {
  margin-top: 50px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about {
  width: 65%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  .left {
    font-size: 1.5rem;
    width: 500px;
  }
  
  .right {
    width: 500px;

    img {
      width: 500px;
    }
  }
}

.services {
  width: 100vw;
  height: 650px;

  display: flex;
  align-items: center;
  justify-content: center;
}


.services2 {
  width: 65%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  font-size: 1.5rem;
  .left {
    img {
      width: 500px;
    }
  }

  .right {
    p {
      font-weight: 500;
      // font-size: 2rem;
    }
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      // height: 180px;
      margin: 0;
      padding: 10px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        .nobg {
          width: 15px;
          height: 15px;
        }
        
        .smaller {
          width: 10px;
          height: 10px;
        }
        img {
          margin-right: 5px;
        }
      }
    }

    .secondary {
      font-size: 1.2rem;
    }
  }
}

.contact {
  width: 100%;
  height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .bg1 {
    width: 100vw;
    height: 750px;
    position: absolute;

    .div {
      width: 100vw;
      height: 745px;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(2px) brightness(0.7);
      position: absolute;
      z-index: 0;
      opacity: 0.75;
    }
  }

  // .bg1 {
  //   width: 100%;
  //   height: 750px;
  //   z-index: 0;
  //   overflow: hidden;
  //   position: absolute;
  //   clip: rect(auto, auto, auto, auto);
  //   -webkit-backface-visibility: hidden;
  //   -moz-backface-visibility: hidden;
  //   backface-visibility: hidden;

  //   img {
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     // z-index: 0;
  //     filter: brightness(0.7) blur(2px);
  //   }
  // }

  .contact2 {
    width: 65%;
    height: 750px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;

    .left {
      font-size: 1.5rem;
      width: 500px;
    }
  
    .right {
      width: 500px;
  
      img {
        width: 500px;
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 180px;
    margin: 0;
    padding: 10px;
  
    .address {
      font-size: 1.2rem;
    }
  }
}

.gallery {
  width: 80%;
  height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
  flex-direction: column;

  .box {
    width: 100%;
    height: 700px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      justify-content: space-evenly;
    }
  }
}

.checkbox {
  display: none
}


@media screen and (max-width: 1560px) {
  .nav {
    transition: all 0.2s ease-in-out;
    transform: translateX(200%);
    height: 100vh;
    width: 50vw;
    z-index: 2;
    align-items: center;
    // text-align: center;
    .elements {
      height: 80vh;
      display: flex;
      flex-direction: column;
      width: 90%;
      align-items: center;

      .logo {
        height: 65px;
        img {
          height: 100%;
        }
      }

      .links {
        height: 70%;
        width: 100%;
        display: flex;
        justify-content: center;
        // text-align: center;

        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .bg {
    background-color: #110E1B;
    z-index: 2;
    position: fixed;
    height: 65px;
    width: 100vw;
    top: 0;
    right: 0;
    transition: all 0.2s ease-in-out;
  }

  .menup {
    display: flex;
    z-index: 3;
    position: fixed;
    right: 65px;
    // top: auto;
    // text-align: center;
    height: 64px;
    align-items: center;
    justify-items: center;
    font-size: 1.5rem;
  }

  .checkbox {
    position: fixed;
    display: block;
    height: 32px;
    width: 32px;
    top: 10px;
    right: 14px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
    background-color: #3d3a50;
  }

  .hamburgerLines {
    // display: block;
    height: 26px;
    width: 32px;
    position: fixed;
    top: 20px;
    right: 15px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .hamburgerLines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #dcdde1;
  }

  .hamburgerLines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .hamburgerLines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .hamburgerLines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  input[type="checkbox"]:checked~.bg {
    transform: translateX(50%);
  }

  input[type="checkbox"]:checked~.nav {
    transform: translateX(100%);
  }

  input[type="checkbox"]:checked~.hamburgerLines .line1 {
    transform: rotate(45deg);
  }

  input[type="checkbox"]:checked~.hamburgerLines .line2 {
    transform: scaleY(0);
  }

  input[type="checkbox"]:checked~.hamburgerLines .line3 {
    transform: rotate(-45deg);
  }
}

@media screen and (max-width: 1500px) {
  .about, .gallery, .services2, .contact2 {
    width: 75%;
  }
}

@media screen and (max-width: 1210px) {
  .about, .gallery, .services2, .contact2 {
    width: 85%;
  }
}

@media screen and (max-width: 1090px) {
  .about, .gallery, .services2, .contact2 {
    width: 90%;
  }
}

@media screen and (max-width: 1000px) {
  h1 {
    text-align: center;
  }

  .main {
    height: calc(100vh - 100px);

    .bg1 {
      height: calc(100vh - 150px);
    }
  }

  .about, .contact2 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .right {
      display: none;
    }
  }

  .services {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .services2 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .right {
      text-align: center;
      ul {
        align-items: center;
        text-align: center;
        font-size: 1.3rem;
      }
    }
    .left {
      display: none;
    }
  }

  .contact {
    // display: flex;
    .contact2 {
      width: 80vw;
      .left {
        width: 80vw;
        ul {
          font-size: 1.3rem;
          align-items: center;
          text-align: center;
        }
      }
    }
  }

  .about {
    text-align: center;
  }
}

@media screen and (max-width: 660px) {
  .nav .elements .logo {
    height: 50px;
  }
}

@media screen and (max-width: 510px) {
  .nav .elements .logo {
    height: 45px;
  }
}

@media screen and (max-width: 450px) {
  .nav .elements .logo {
    height: 35px;
  }

  .gallery {
    height: 90%;
    .box {
      width: 85%;
      height: 90%;
      overflow: visible;
      .content {
        overflow: visible;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .contact {
    .contact2 {
      width: 100vw;
      align-items: center;
      justify-content: center;
      .left {
        width: 90%;
        ul {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .nav .elements .logo {
    height: 30px;
  }
}

@media screen and (max-width: 300px) {
  .nav .elements .logo {
    height: 25px;
  }
}

@media screen and (max-width: 320px) {
  .contact {
    .contact2 {
      .left {
        ul {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 290px) {
  .contact {
    .contact2 {
      .left {
        ul {
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-height: 780px) {
  .about {
    .left {
      font-size: 1.2rem;
    }
  }
}