.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.gallery {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.item {
  position: absolute;
  opacity: 0;
  transition: all .2s ease-in-out;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
}

.itemprev {
  display: flex;
  transform: opacity(0) scale(1);
  transition: all 1s ease-out;
}

.itemactive {
  display: flex;
  overflow: hidden;
  transform: opacity(1) scale(1);
  transition: all 1s ease-out;
  opacity: 1;
}

.itemnext {
  display: flex;
  transform: opacity(0) scale(1);
  transition: all 1s ease-out;
}

.item img {
  height: 150%;
  width: 100%;
  border-radius: 15px;
  filter: blur(2px) brightness(0.7)
}